@import "src/styles/settings";
/* stylelint-disable-next-line selector-class-pattern */
.react-datepicker {
  &__input-container {
    position: relative;
    display: inline-block;
    & > input {
      box-sizing: border-box;
      padding: .75rem;
      margin: .0625rem;
      background-color: #fefdff;
      border: .125rem solid colors(gray-300);
      border-radius: .25rem;
      &:focus {
        border: .125rem solid colors(semantic);
        outline: none;
        box-shadow: 0 0 0 .25rem rgb(0 128 255 / 10%);
      }
      &:hover {
        border: .125rem solid colors(semantic);
        outline: none;
      }
    }
  }
}
/* stylelint-disable-next-line selector-class-pattern */
.react-datepicker-wrapper {
  width: auto;
}
/* stylelint-disable-next-line selector-class-pattern */
.react-datepicker-popper {
  z-index: 1000;
}
