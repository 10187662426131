@use "./settings.scss" as *;
html {
  background-color: colors(gray-50);
  @include mq() {
    height: 100%;
    overflow: auto;
  }
}
body {
  position: relative;
  margin: 0;
  font-family: Roboto, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: .875rem;
  font-feature-settings: "palt";
  line-height: 1.3;
  color: colors(black);
  letter-spacing: .04em;
  word-break: break-all;
  word-wrap: break-word;
  background-color: colors(gray-50);
}

// numberのspinner隠し
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: textfield;
}
a {
  color: colors(link-text);
  text-decoration: none;
  &:hover {
    color: colors(link-hover);
    text-decoration: underline;
  }
}
